import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const TinyHouse = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Tiny House – Großes Glück auf kleinem Raum?" showCalc={false} />
            <Article>
                <h2>Was sind Tiny Houses?</h2>
                <p>
                    Mit der Möglichkeit kostengünstiger Finanzierung von Immobilien durch die schon langanhaltenden
                    Niedrigzinsen gibt es seit mehreren Jahren einen Bauboom. Einerseits ermöglicht dieser es vielen,
                    endlich Besitzer des ersehnten Eigenheims zu werden, andererseits steigen durch die erhöhte
                    Nachfrage die Immobilienpreise in bisher ungekannte Höhen. Hier kann einem gerade in
                    st&auml;dtischer Lage – schon mal schwindelig werden. Für die, die auf den Luxus
                    überschw&auml;nglichen Wohnraums gut und gerne verzichten können, aber weiterhin vom eigenen
                    H&auml;uschen tr&auml;umen, bietet ein Trend aus den USA eine echt-grüne Alternative: Tiny Houses!
                    Minih&auml;user, die schon bei um die 12 m^2 Wohnfl&auml;che gutes, komfortables Leben versprechen,
                    wenn gewünscht auch direkt auf R&auml;dern, sollen mehr sein als nur bessere Wohnmobile.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Seit wann gibt es Tiny Houses?</h2>
                <p>
                    Ab der zweiten Hälfte des letzten Jahrhunderts ging der Trend in Europa grundsätzlich in Richtung
                    mehr Wohnraum. Waren es beispielsweise nach dem Krieg in Westdeutschland 21 m2, die einer Person zur
                    Verfügung standen, sind es heute 47 m2. Das Tiny House stellt hier eine echte Trendwende dar.
                </p>
                <p>
                    Verrückte Tüftler, die erste motorisierte Häuschen auf Rädern bauten, gibt es dabei schon seit den
                    1920er Jahren. Das Tiny House in seiner heutigen Form aber findet, so Experten, seinen Vater im
                    US-Amerikaner Jay Shafer. Der selbst in seinem nur 8.9 m2 großen Haus wohnende Architekt baute sein
                    erstes Tiny House 1999 in Iowa. Die Finanzkrise von 2008, die drohende Klimakrise wie auch der Trend
                    zum Minimalismus sorgten dann Anfang der 2010er für eine wachsende mediale Popularität der
                    kostengünstigeren, grüneren und reduzierten Tiny Houses. Seitdem wächst die Zahl der Anbieter auch
                    in Österreich kontinuierlich.
                </p>
                <hr />

                <h2>Was kosten Tiny Houses?</h2>
                <p>
                    Prinzipiell lassen sich Tiny Houses natürlich selbst bauen. Auch wenn es Kosten spart, ohne etwaige
                    Schreinerausbildung ist das Resultat für viele meist nicht allzu überzeugend, vor allem wenn man das
                    Tiny House langfristig nutzen möchte. Daher sollte man sich bei Interesse üblicherweise an die
                    inzwischen große Zahl von professionellen Tiny House Konstrukteuren wenden. Die Preise variieren
                    nach Anbieter, Größe und Ausstattung, wie auch ob es sich auf einem fahrbaren Untersatz befinden
                    soll, was dann etwas mehr kostet. Uns sind erste Angebote für die kleinsten bekannt, die bei 30.000
                    Euro starten. Je größer das Haus, desto schneller ähneln die Quadratmeterpreise dann auch denen der
                    normalen mit vergleichbarer Ausstattung. Die Minihäuser bieten jedoch nicht nur beim Kaufpreis viel
                    Einsparpotenzial. Denn Kaufnebenkosten sind abhängig vom Kaufpreis und fallen damit niedriger aus
                    und auch die regulären Nebenkosten sind bei geringerer Wohnfläche natürlich kleiner. In Zeiten von
                    steigenden Energiepreisen ein klares Plus.
                </p>
                <p>
                    Mit welchen Finanzierungskonditionen der Wunsch vom eigenen Tiny House für dich schon bald erfüllbar
                    werden könnte, kannst du hier mit dem miracl Kredit-Rechner herausfinden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Brauche ich für mein Tiny House ein Grundstück?</h2>
                <p>
                    Ja, dieses muss aber nicht dir gehören. Neben dem Grundstückserwerb gibt es auch in manchen Fällen
                    die Möglichkeit, einen Pachtvertrag abzuschließen. Dies bietet sich vor allem dann an, wenn sich für
                    ein transportables Tiny House auf Rädern entschieden wurde. Hierbei ist zu beachten, dass laut
                    österreichischem Baurecht die minimale Laufzeit eines Pachtvertrages zehn Jahre beträgt. Ein Zuhause
                    finden transportable Tiny Houses daher immer häufiger auf Campingplätzen, wo sie nach Vereinbarung
                    auf Dauer abgestellt werden können.
                </p>
                <p>
                    Bei der Suche nach einem geeigneten Standort zu berücksichtigen ist in jedem Fall das Vorhandensein
                    eines Anschlusses an das öffentliche Versorgungsnetz. Ist das Tiny House auf Dauer auf einem
                    Grundstück abgestellt, benötigt es außerdem eine Genehmigung durch die örtliche Baubehörde. Ist es
                    darüber hinaus im Boden verankert, muss beachtet werden, dass die baurechtlichen Ansprüche hin
                    nochmal strenger sind.
                </p>
                <hr />

                <h2>Welche Finanzierungsmöglichkeiten für Tiny Houses gibt es?</h2>
                <p>
                    Die Frage nach der Finanzierung des Tiny Houses stellt sich dann, wenn nicht genügend flüssiges
                    Eigenkapital zur Verfügung steht.
                </p>
                <p>
                    Einige Unterschiede zur regulären{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    über einen Hypothekarkredit können hier bestehen, denn im Gegensatz zur USA ist es in Österreich für
                    viele Banken noch unüblich, den Kauf von Tiny Houses über Immobilienkredite zu ermöglichen. Diese
                    Form des{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredites
                    </Link>{" "}
                    basiert nämlich maßgeblich auf der Eintragung einer Grundschuld im Grundbuch – der Hypothek. Das ist
                    bei einem Tiny House, was nicht auf einem fixen Grundstück steht, gar nicht möglich, womit das
                    Hauptanliegen der Bank – ihre Sicherheit – vielleicht nicht erfüllt werden kann. Denn der{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    wird nun nur gedeckt vom Wiederverkaufspreis des Tiny Houses und nicht auch vom Grundstückspreis.
                    Außerdem ist es hier zu Lande noch immer unüblich, dass Banken Immobilienkredite unter 30.000 Euro
                    gewähren.
                </p>
                <p>
                    Aber: Wegen der wachsenden Nachfrage haben bereits einige Kreditinstitute ihr Angebot um
                    Finanzierungen für nicht-klassische Wohnformen erweitert. Bei ausreichender Kreditwürdigkeit sollte
                    dem Traum vom Tiny House also nichts im Wege stehen, der richtige Finanzpartner wird zu finden sein.
                </p>
                <hr />

                <h2>Wann und für wen lohnt sich ein Tiny House?</h2>
                <p>
                    Mit drei Kindern dauerhaft in einem Minihaus – das könnte wohl schnell zu einer stressigen
                    Angelegenheit werden. Das Angebot, seinen Hauptwohnsitz in einem Tiny House zu finden, richtet sich
                    allen voran an diejenigen Personen, die Kosten sparen, ihren ökologischen Fußabdruck verringern und
                    dabei auf massig Platz verzichten können und wollen. Gerade Tiny Houses auf Rollen bieten hier
                    Leuten, die häufig ihren Lebensmittelpunkt wechseln, die Möglichkeit, ihre eigenes &bdquo;Dach über
                    dem Kopf&ldquo; einfach mitzunehmen.
                </p>
                <p>
                    Aber Tiny Houses als nicht familienfreundlich abzustempeln und damit gänzlich von ihnen abzusehen
                    ist etwas voreilig. Durch ihren kleineren Preis bieten sich diese als leistbares Ferien- oder
                    Wochenendhaus im Grünen an, auch für die ganze Familie.
                </p>
                <p>
                    Genauso können Tiny Houses aber auch als kleine Vorsorgewohnung dienen. Vermietet man sie kurz oder
                    langfristig können auch Menschen mit nicht so viel Eigenkapital durch den vergleichsweise geringen
                    Kaufpreis eine gute Investition in ihre Rentenvorsorge tätigen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"tinyHouse"}></BreadcrumbList>
            <ArticleStructuredData page={"tinyHouse"} heading={"Tiny House – Großes Glück auf kleinem Raum?"} />
        </Layout>
    );
};

export default TinyHouse;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.tiny-house", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
